/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import SEO from '../components/seo'

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
      <SEO
       
       pagePath="/"
       schema={`{
         "@context": "http://schema.org/",
         "@type": "Organization",
         "name": "Crucelfer - Comércio de Ferramentas",
         "address": {
           "@type": "PostalAddress",
           "streetAddress": "Rua da Amara, lote 1, Quintã do Loureiro",
           "addressLocality": "Aveiro",
           "addressRegion": "Portugal",
           "postalCode": "3800-567"
         },
         "telephone": "934913200"
       }`}
     />
        <main>{children}</main>
   
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

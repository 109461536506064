import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import '../styles/header.css'
import $ from 'jquery' // important: case sensitive.
import CloseIcon from '@material-ui/icons/Close';
import scrollTo from 'gatsby-plugin-smoothscroll';
import logo from "../images/logo2.png" // Tell webpack this JS file uses this image
import GoogleMap from '../components/GoogleMap'
import Form from '../components/Form'
import menu from "../images/ic_menu.svg" // Tell webpack this JS file uses this image
import { TiHome } from 'react-icons/ti';
import {FaPhone} from 'react-icons/fa';
import { FaMapPin } from "react-icons/fa";

if (typeof window !== `undefined`) {
var desktopquery = window.matchMedia('(min-width: 992px)');
var mobilequery = window.matchMedia('(max-width: 991px)');
}
function clickMenu(){

if (desktopquery.matches){
$('#menu-section').animate({
left: '0%'
});
};
if (mobilequery.matches){
$('#menu-section').css('display','block');
$('#menu-section').animate({
left: '0'
});
};
}
function closeMenu(){
if (desktopquery.matches){
$('#menu-section').animate({
left: '100%'
});
};
if (mobilequery.matches){
   

$('#menu-section').animate({
left: '100%'
});
};
}
const Header = () => {
return (
<div>

   <div id="menu-section">
      <div className="wrapper">
      <div className="col-right-mobile">
            <div className="wrapper2">
               <div className="header">
                  <div className="title hk-light">
                  <img src={logo} alt="Logo" />
                  </div>
                  <div className="close-icon" onClick={closeMenu}>
                     <CloseIcon  fontSize="small"  />
                  </div>
               </div>
               <div className="body">
               <Form />
               </div>
            </div>
         </div>
         <div className="col-left">
         <GoogleMap />
         <div className="contacto">
            <div className="wrapper">
               <div className="contactos play-regular">
                  <div className="col-1">
                     <div className="linha">
                     <span><FaPhone/>(+351) 234 913 204*<br></br>geral@crucelfer.pt</span>
                     
                     </div>
                  
                  </div>
                  <div className="col-2">
                  <span><TiHome /></span> <span className="span">R. do Senhor dos Milagres, 34 <br></br>3800-261 Aveiro</span>
                  </div>
                
                 <div className="col-3">
                  <span><FaMapPin /></span> <a href="https://www.google.com/maps?rlz=1C5CHFA_enPT1013PT1013&um=1&ie=UTF-8&fb=1&gl=pt&sa=X&geocode=KUGiUaoGgyMNMRp1drAAa_bS&daddr=R.+do+Sr.+dos+Milagres+34,+3800-261+Aveiro" target="_blank"><span className="span">Obter direções <br></br> </span></a>
                  </div>
     
               </div>
               
            </div>
            <div className="footer-bottom">
               <div className="wrapper-bottom play-regular">
                  <span>*Chamada para rede fixa nacional</span><br></br>
                  <span>Crucelfer - Comércio de Ferramentas, LDA © 2024  |   Handcrafted by </span> <a target="_blank" href="https://www.fortydegrees.pt" className="play-bold forty">40º</a>
               </div>
            </div>
         </div>
         </div>
         <div className="col-right-desktop">
            <div className="wrapper2">
               <div className="header">
                  <div className="title hk-light">
                  <img src={logo} alt="Logo" />
                  </div>
                  <div className="close-icon" onClick={closeMenu}>
                     <CloseIcon  fontSize="small"  />
                  </div>
               </div>
               <div className="body">
               <Form />
               </div>
            </div>
         </div>
      </div>
   </div>
   <Headroom>
      <div id="barra-nav">
         <div className="nav-container">
            <div className="brand">
               <img src={logo} alt="Logo" />
            </div>
            <div className="menu">
               <img onClick={clickMenu} src={menu} alt="Logo" />
            </div>
         </div>
      </div>
   </Headroom>
</div>
)
}
export default Header